var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.curriculumValues ? _c('div', {
    staticClass: "mt-3"
  }, [_c('BaseStepperDynamic', {
    attrs: {
      "selectedSteps": _vm.selectedSteps,
      "isPost": true,
      "isDraft": true,
      "postBtn": "Send til godkjenning",
      "draftBtn": _vm.isDraft ? 'Oppdater utkast' : 'Lagre som utkast',
      "loading": _vm.savingDraft
    },
    on: {
      "cancel": _vm.cancel,
      "post": function post($event) {
        _vm.displayCreateCurriculumModal = true;
      },
      "draft": _vm.saveDraft
    },
    scopedSlots: _vm._u([_vm._l(_vm.selectedSteps, function (step, idx) {
      return {
        key: "step".concat(step.id),
        fn: function fn() {
          return [_c(step.component, {
            key: idx,
            ref: "step".concat(step.id),
            refInFor: true,
            tag: "component",
            attrs: {
              "isDraft": _vm.isDraft,
              "nusCodes": _vm.nusCodes
            },
            model: {
              value: _vm.curriculumValues,
              callback: function callback($$v) {
                _vm.curriculumValues = $$v;
              },
              expression: "curriculumValues"
            }
          })];
        },
        proxy: true
      };
    })], null, true)
  }), _vm.displayCreateCurriculumModal ? _c('BaseModal', {
    on: {
      "close": function close($event) {
        _vm.displayCreateCurriculumModal = false;
      }
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline"
  }, [_vm._v(_vm._s(_vm.createCurriculumModalTitle))]), _c('v-card-text', [_c('transition', {
    attrs: {
      "name": "slide",
      "mode": "out-in"
    }
  }, [_vm.createdCurriculum ? _c('UploadFile', {
    attrs: {
      "curriculumId": _vm.createdCurriculum.id
    }
  }) : _c('div', [_vm._v(" Ved å akseptere her vil studieplanen sendes til godkjenning. Studieplanen kan deretter bli godkjent av en studieadministrator og gjennomføringsplan kan opprettes. ")])], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _vm.createdCurriculum ? _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push("/studieplaner");
      }
    }
  }, [_vm._v("Fullfør")]) : [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.displayCreateCurriculumModal = false;
      }
    }
  }, [_vm._v("Avbryt")]), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.approveCurriculum
    }
  }, [_vm._v("Send til godkjenning")])]], 2)], 1)], 1) : _vm._e()], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }