
import { ApiGetTeachingMaterialDto } from "@/api/generated/Api";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { useAutoDeepCloneProp } from "@/fragments/course/useAutoDeepCloneProp";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { useStore } from "@/shared/useHelpers";
import { UpsertCurriculumDto } from "@/store/modules/plans/interfaces/UpsertCurriculumDto.interface";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  name: "TeachingMaterials",
  components: { BaseTableFiltered },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const store = useStore<StoreState>();

    const search = ref("");
    const curriculumValues = useAutoDeepCloneProp<UpsertCurriculumDto>(props, "value");

    const getTeachingMaterialName = (teachingMaterials: ApiGetTeachingMaterialDto[], currentTeachingMaterial: number) =>
      teachingMaterials?.find((item: ApiGetTeachingMaterialDto) => item.id === currentTeachingMaterial)?.name;

    const addTeachingMaterial = (item: ApiGetTeachingMaterialDto) => {
      curriculumValues.value.course?.teachingMaterials?.push(item.id);
      context.emit("input", deepCloneObject(curriculumValues.value));
    };

    const removeTeachingMaterial = (itemId: number) => {
      if (curriculumValues.value.course?.teachingMaterials && curriculumValues.value.course.teachingMaterials.length) {
        curriculumValues.value.course.teachingMaterials = curriculumValues.value.course?.teachingMaterials?.filter(
          (currentTeachingMaterial: number) => currentTeachingMaterial !== itemId
        );
        context.emit("input", deepCloneObject(curriculumValues.value));
      }
    };

    const headers = [
      { text: "Kategori", value: "category" },
      { text: "Handlinger", value: "actions" },
      { text: "Navn", value: "name" },
      {
        text: "Beskrivelse",
        value: "description",
      },
    ];

    const validate = () => {
      context.emit("input", deepCloneObject(curriculumValues.value));
      return true;
    };

    return {
      headers,
      search,
      curriculumValues,
      getTeachingMaterialName,
      addTeachingMaterial,
      removeTeachingMaterial,
      validate,
      teachingMaterials: computed(() => store.state.teachingMaterials.teachingMaterials),
    };
  },
});
