
import { deleteFile, getCurriculumFilesById } from "@/api/file.api";
import CurriculumSection from "@/components/curriculum/CurriculumSection.vue";
import { defineComponent } from "@vue/composition-api";

interface ComponentData {
  files: any[];
}

export default defineComponent({
  name: "UploadFile",
  components: { CurriculumSection },
  data(): ComponentData {
    return {
      files: [],
    };
  },
  props: {
    curriculumId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    deleteFile(fileId: any): any {
      this.files = this.files.filter((file: any) => file.id != fileId);
      deleteFile(fileId);
    },
    openUploadDialog() {
      // TODO
      // this.$dialog({
      //   component: UploadDialog,
      //   parent: this,
      //   id: this.curriculumId,
      //   category: "",
      //   entity: "curriculum",
      // }).onOk((files) => (this.files = [...(this.files || []), ...files]));
    },
  },
  async mounted() {
    const res = await getCurriculumFilesById(this.curriculumId);
    for (const file of res.data) {
      this.files.push({
        id: file.id,
        originalFileName: file.originalFileName,
        description: file.description,
        category: file.category,
        mimeType: file.mimeType,
      });
    }
  },
});
