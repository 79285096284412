var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CurriculumSection', {
    attrs: {
      "title": "Filer"
    }
  }, [_c('div', _vm._l(_vm.files, function (file, idx) {
    return _c('v-chip', {
      key: idx,
      staticClass: "ma-2",
      attrs: {
        "close": ""
      },
      on: {
        "click:close": function clickClose($event) {
          return _vm.deleteFile(file.id);
        }
      }
    }, [_vm._v(" " + _vm._s(file.originalFileName) + " ")]);
  }), 1), _c('v-btn', {
    staticClass: "elevation-0",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.openUploadDialog
    }
  }, [_vm._v("Legg ved fil")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }